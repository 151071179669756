import HttpRequest from './HttpRequest'

class SolutionTypeProvider extends HttpRequest {
  findAll () {
    return this.get('/solution-type')
  }

  findOne (id) {
    return this.get(`/solution-type/${id}`)
  }

  create (payload) {
    return this.post('/solution-type', payload)
  }

  update (id, payload) {
    return this.patch(`/solution-type/${id}`, payload)
  }

  softDelete (id) {
    return this.delete(`/solution-type/${id}`)
  }

  paginate (page = 1, limit = 10, query) {
    return this.get(`/solution-type?page=${page}&limit=${limit}`, query)
  }
}

export default SolutionTypeProvider
